import * as R from 'ramda'
import * as React from 'react'
import { graphql } from 'gatsby'

//
import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import Layout from '../components/layout'
import HeroInternal from '../components/HeroInternal'
import SectionContent from '../components/sections/section-content'
import SectionOnlyContent from '../components/sections/section-only-content'
import TwoCols from '../components/sections/two-cols'
import BlockContent from '../components/BlockContent'

export default ({data}) => {
  const page = data.sanityPage

  return (
    <Layout slug={page.slug}>
      <HeroInternal
        slug={page.slug.current}
        {...R.find(R.propEq('_type', 'heroImage'))(page._rawContent)}
      />
      <section className="wrapper style1 page page--services">
        <div className="inner">
          <div className="spotlights">
            <section>
              <div className="content">
                <header className="major">
                  <h3>{(R.find(R.propEq('_type', 'section_content'))(page._rawContent)).title}</h3>
                </header>
                  <div>
                    <BlockContent blocks={(R.find(R.propEq('_type', 'section_content'))(page._rawContent)).body} />
                    <div className="row">
                      {
                        mapIndexed( (col, index) => {
                          return(
                            <div className="col-6 col-12-small" key={index}>
                              <BlockContent blocks={col.body} />
                            </div>
                          )
                        })((R.find(R.propEq('_type', 'ctaColumns'))(page._rawContent)).columns)
                      }
                    </div>
                    <BlockContent blocks={(R.find(R.propEq('_type', 'section_content_only'))(page._rawContent)).body} />
                  </div>
              </div>
            </section>
            {
              mapIndexed( (item, index) => {
                if(R.equals(item._type, 'service')) {
                  return(
                    <section>
                      <div className="content">
                        <header className="major">
                          <h3>{item.title}</h3>
                        </header>
                        <div>
                          <div className="fusion-text" />
                          <div className="fusion-text">
                            <BlockContent blocks={item.body} />
                          </div>
                        </div>
                      </div>
                      <div className="image">
                        <img src={item.image.asset.url} alt="" />
                      </div>
                    </section>
                  )
                }
              })(page._rawContent)
            }
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const ContactQuery = graphql`
  query {
    sanityPage(slug: {current: {eq: "services"}}) {
      _rawContent(resolveReferences: {maxDepth: 4})
      slug {
        current
      }
      pageTitle
    }
  }
`
